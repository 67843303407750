.page {
  padding-bottom: 150px;
}

.works {
  margin-bottom: 100px;
}

.works_list {
  margin-bottom: 0;
}

.clients {
  padding: 100px 0 150px;
}

.notification {
  margin-bottom: 150px;
}

.insights {
  margin-bottom: 130px;
}

.tabs {
  padding: 46px 0;
  background-color: #f8f8ff;
}

.tabs_title,
.works_title,
.insights_title {
  text-align: center;
  font-family: Ubuntu, sans-serif;
  font-weight: 700;
  font-size: 56px;
  line-height: 120.1%;
  letter-spacing: 0.05em;
  margin-bottom: 120px;
  text-transform: uppercase;
  color: #777793;
}

.posts h2 {
  color: var(--blue);
}

.team {
  margin-bottom: 120px;
}

.intro {
  background: linear-gradient(180deg, #dedef5 0%, rgba(248, 248, 255, 0%) 100%);
  margin-bottom: 80px;
}

.intro_inner {
  background-image: url('../../public/img/main.webp');
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  padding: 25% 0%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro_title {
  max-width: 941px;
  margin: 0;
  font-family: Ubuntu, sans-serif;
  font-weight: 700;
  font-size: 100px;
  line-height: 120.1%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #777793;
  text-align: center;
}

.devided {
  width: 100%;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(123, 123, 156, 0%) 0%,
    #7b7b9c 49.94%,
    rgba(123, 123, 156, 0%) 100%
  );
}

.works_all {
  display: flex;
  justify-content: center;
}
@media (max-width: 1365px) {
  .tabs_title {
    margin-bottom: 45px;
  }
}
@media (max-width: 1200px) {
  .works {
    padding-top: 40px;
    margin-bottom: 30px;
  }

  .intro_title {
    font-size: 60px;
  }

  .tabs_title,
  .works_title,
  .clients_title,
  .insights_title {
    margin-bottom: 40px;
  }

  .notification {
    margin-bottom: 40px;
  }

  .insights {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .page {
    padding-bottom: 80px;
  }

  .intro {
    margin-bottom: 0;
  }

  .intro_inner {
    margin-top: 70px;
    min-height: 580px;
    background-position: center;
    background-image: url('../../public/img/main-mobile.webp');
  }

  .intro_title {
    max-width: 350px;
    margin: 0 auto 100px;
    font-size: 35px;
  }

  .works_title,
  .clients_title,
  .insights_title {
    font-size: 22px;
    margin-bottom: 30px;
  }

  .tabs_title {
    font-size: 22px;
    margin: 0;
  }

  .clients {
    padding: 30px 0 80px;
  }

  .notification {
    margin-bottom: 50px;
  }

  .insights {
    margin-bottom: 40px;
  }

  .team {
    margin-bottom: 60px;
  }

  .tabs {
    padding: 20px 0;
  }
}
